<template>
	<div>
		<footer>
			<div class="row">
				<div class="columns column3">
					<img
						src="~/assets/images/logo-hotelbreathe-leiden-white.png"
						loading="lazy"
						alt="Breathe Hotel Leiden"
						class="footer-logo"
					/>
				</div>
				<div class="columns column3">
					<h3>{{ $t('contact') }}</h3>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h3>{{ defaults[locale].website.hotelName }}</h3>
					<p>
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<br />
						<span>{{ defaults[locale].website.country }}</span>
						<br />
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<hr />
				</div>
			</div>
		</footer>
		<footer class="author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1">|</span>
						</span>
						<span v-if="defaults[locale].cookieConsent.published">
							<span> | </span>
							<a href="#" @click.prevent="openConsent">{{ $t('consentOpen') }}</a>
						</span>
					</p>
					<p class="align-right">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();
const { openConsent } = useCookieConsent();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
footer {
	background: $footer-background-color;
	color: #fff;
	padding: 75px 0 10px;
	font-size: 15px;
}

footer hr {
	background: #f4f0e7;
}

footer a {
	color: $footer-link-color;

	&:hover {
		color: $cta-color;
	}
}

footer h3 {
	color: #fff;
}

footer.author {
	padding: 10px 0;
	font-size: 12px;
}

.author .column12 {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.author img {
	max-width: 20px;
	margin: 0 1px 0 2px;
}

.footer-logo {
	max-width: 200px;
	margin: 0 0 30px;
}

.socials a {
	color: #fff;
	background: $cta-color;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 22px;
	margin: 5px 5px 5px 0;
	text-decoration: none;
}

.socials a:hover {
	color: #fff;
	background: $cta-color-hover;
}

@media (max-width: 920px) {
	footer .columns {
		margin: 20px auto;
	}

	.author .columns {
		margin: 0;
		flex-flow: wrap column-reverse;
	}

	.author p {
		width: 100%;
		margin: 0 0 5px;
		text-align: center;
	}
}

@media (max-width: 680px) {
	footer {
		text-align: center;
	}
}
</style>
