<template>
	<div>
		<transition name="popup">
			<div v-show="show" class="notification popup">
				<div v-click-outside="hide" :class="{ 'no-img': !notification.image }" class="popup-row">
					<div class="popup-body">
						<div class="popup-content">
							<h4>{{ notification.header }}</h4>
							<div v-parse-links class="content" v-html="notification.content" />
							<a
								v-if="notification.button"
								target="_blank"
								:href="notification.link"
								class="button button-compact"
							>
								{{ notification.button }}
							</a>
						</div>
					</div>
					<div v-if="notification.image" class="popup-image">
						<div class="circle" @click="hide">
							<font-awesome-icon icon="fal fa-xmark" size="1x" />
						</div>
						<picture>
							<source v-if="notification.imageWebp" :srcset="notification.imageWebp" type="image/webp" />
							<source :srcset="notification.image" />
							<img :src="notification.image" />
						</picture>
					</div>
				</div>
				<div v-show="show" class="background close" />
			</div>
		</transition>
	</div>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const show = ref(false);

onMounted(async () => {
	if (!window.sessionStorage || !props.notification) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.notification.ID}`)) {
		return;
	}

	const showNotification = await getTrigger(props.notification.trigger);
	show.value = await showNotification(props.notification.ID);

	window.sessionStorage.setItem(`displayed-notification-${props.notification.ID}`, 'true');
});

const hide = () => {
	show.value = false;
};
</script>

<style lang="scss" scoped>
.popup-enter-active {
	animation: bounce-in 0.4s;
}

.popup-leave-active {
	transition: all 0.3s ease;
	opacity: 0;
}

.background {
	background: rgb(0 0 0 / 50%);
	position: fixed;
	inset: 0;
	z-index: 1;
}

.popup {
	position: fixed;
	inset: 0;
	z-index: 99999;

	.popup-row {
		display: flex;
		flex-flow: wrap row;
		justify-content: space-between;
		align-items: stretch;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 900px;
		z-index: 3;
		width: 100%;

		&.no-img {
			justify-content: center;
		}
	}

	.popup-image {
		width: 55%;

		img {
			object-fit: cover;
			width: 100%;
		}

		.circle {
			cursor: pointer;
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: #fff;
			box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);
			color: #000;
			height: 30px;
			vertical-align: middle;
			width: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
		}
	}

	.popup-body {
		position: relative;
		background: $body-background-color;
		z-index: 2;
		width: 45%;
		padding: 70px 60px;
		display: flex;
		align-items: center;

		.content {
			margin: 10px 0;
		}

		:deep(p) {
			margin: 5px 0;
		}

		.button {
			margin-bottom: 0;
		}

		h4 {
			letter-spacing: 0;
			font-size: 24px;
			margin-top: 0;
		}
	}
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

@media (max-width: 900px) {
	.popup {
		.popup-row {
			flex-flow: column-reverse wrap;
			max-width: 500px;
			width: 90%;
		}

		.popup-body {
			padding: 30px;
		}

		.popup-image,
		.popup-body {
			width: 100%;
		}
	}
}

@media (max-width: 780px) {
	.popup {
		.popup-body {
			padding: 20px 30px;
		}
	}
}

@media (max-width: 480px) {
	.popup {
		.popup-image img {
			max-height: 200px;
		}
	}
}
</style>
