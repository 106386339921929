<template>
	<div>
		<transition :name="`notification-${position}`">
			<div
				v-show="show"
				:style="{ transform: minimized ? `translate(0, calc(100% - ${boxToggleHeight}px + 2px))` : '' }"
				class="notification newsletter-box"
				:class="[
					{
						mobile: notification.showOnMobile,
						tablet: notification.showOnTablet,
						desktop: notification.showOnDesktop,
						minimized,
					},
					position,
				]"
			>
				<div class="notification-row">
					<div ref="header" class="box-toggle">
						<h4 @click="minimize">
							{{ notification.header }}
						</h4>
						<div class="circle">
							<font-awesome-icon
								v-show="!minimized"
								class="icon minimize"
								icon="fal fa-chevron-down"
								size="1x"
								@click="minimize"
							/>
							<font-awesome-icon
								v-show="minimized"
								class="icon close"
								icon="fal fa-xmark"
								size="1x"
								@click="() => (show = false)"
							/>
						</div>
					</div>
					<div v-if="notification.image" class="image">
						<picture>
							<source :srcset="notification.imageWebp" type="image/webp" />
							<source :srcset="notification.image" />
							<img :src="notification.image" />
						</picture>
					</div>
					<div class="body">
						<div v-html="notification.content" />
						<!-- Begin Mailchimp Signup Form -->
						<div v-if="mailchimpURL" id="mc_embed_signup">
							<form
								id="mc-embedded-subscribe-form"
								:action="mailchimpURL"
								method="post"
								name="mc-embedded-subscribe-form"
								class="validate"
								target="_blank"
								novalidate
							>
								<div id="mc_embed_signup_scroll">
									<div class="mc-field-group">
										<input
											id="mce-EMAIL"
											type="email"
											value=""
											name="EMAIL"
											:placeholder="$t('formEmail')"
											class="required email"
										/>
										<input
											id="mc-embedded-subscribe"
											type="submit"
											:value="$t('buttonSubscribe')"
											name="subscribe"
											class="button button-compact"
										/>
									</div>
									<div id="mce-responses" class="clear">
										<div id="mce-error-response" class="response" style="display: none" />
										<div id="mce-success-response" class="response" style="display: none" />
									</div>
									<!-- real people should not fill this in and expect good things
								- do not remove this or risk form bot signups-->
									<div style="position: absolute; left: -5000px" aria-hidden="true">
										<input type="text" :name="`b_${uID}_${formID}`" tabindex="-1" value="" />
									</div>
									<div class="clear" />
								</div>
							</form>
						</div>
						<!--End mc_embed_signup-->
					</div>
				</div>
				<div v-show="show" class="background close" />
			</div>
		</transition>
	</div>
</template>

<script setup>
import getTrigger from '@becurious/conversiontriggers';

const props = defineProps({
	notification: { type: Object, default: () => {} },
});

const header = ref(null);
const position = props.notification.conversionPosition;
const show = ref(false);
const minimized = ref(false);
const boxToggleHeight = ref(0);

onMounted(async () => {
	if (!window.sessionStorage || !props.notification) {
		return;
	}

	if (window.sessionStorage.getItem(`displayed-notification-${props.notification.ID}`)) {
		return;
	}

	const showNotification = await getTrigger(props.notification.trigger);
	show.value = await showNotification(props.notification.ID);

	window.sessionStorage.setItem(`displayed-notification-${props.notification.ID}`, 'true');
});

const mailchimpURL = computed(() => {
	let url;

	try {
		url = new URL(props.notification.mailchimpURL);
	} catch (_) {
		return '';
	}

	return url.toString();
});

const uID = computed(() => {
	if (!mailchimpURL.value) {
		return '';
	}

	return new URL(mailchimpURL.value).searchParams.get('u');
});

const formID = computed(() => {
	if (!mailchimpURL.value) {
		return '';
	}

	return new URL(mailchimpURL.value).searchParams.get('id');
});

const minimize = () => {
	if (!boxToggleHeight.value) {
		boxToggleHeight.value = header.value.offsetHeight;
	}

	minimized.value = !minimized.value;
};
</script>

<style lang="scss" scoped>
form input {
	width: 100%;
	padding: 15px;
	margin: 5px 0;
	border: 1px solid #efefef;
}

.newsletter-box {
	max-width: 350px;
	transition: all 0.3s ease-in;
	box-shadow: 0 0 4px 1px rgb(0 0 0 / 15%);

	.body {
		background: $body-background-color;
		padding: 5px 20px 20px;
		width: 100%;
	}

	.box-toggle {
		cursor: pointer;
		background: $cta-color;
		width: 100%;

		.circle .icon {
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: $body-background-color;
			height: 13px;
			vertical-align: middle;
			width: 13px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px;
		}

		h4 {
			color: #fff;
			letter-spacing: 0;
			margin: 0;
			font-size: 24px;
			padding: 15px 20px;
		}
	}
}

@media (max-width: 480px) {
	.background {
		background: rgb(0 0 0 / 50%);
		position: fixed;
		inset: 0;
		z-index: 1;
		transition: all 0.4s ease;
	}

	.newsletter-box {
		.notification-row {
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			align-items: stretch;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 900px;
			z-index: 3;
			width: calc(100% - 20px);

			.image {
				width: 100%;

				img {
					object-fit: cover;
					width: 100%;
					max-height: 180px;
				}
			}

			h4 {
				font-size: 20px;
				pointer-events: none;
			}

			.icon.minimize {
				display: none !important;
			}

			.icon.close {
				display: block !important;
			}
		}
	}

	.newsletter-box:not(.mobile) {
		display: none !important;
	}
}

@media (max-width: 801px) {
	.newsletter-box:not(.tablet) {
		display: none !important;
	}
}

@media (min-width: 1025px) {
	.newsletter-box:not(.desktop) {
		display: none !important;
	}
}
</style>
